import { Account, Group, Invite, LoginToken, NetworkDoc, SearchIndexAPIKey, SearchIndexErrorLogItem, SearchIndexResumeLogItem, Tag, Team, User } from './api.models';
import { NotificationItem } from './notifications';
import { PermissionCacheDocument, Role } from '../src/api/v3/permissions/permission-models';
import { Link } from './link-types';
import { PollDbDoc } from './poll-types';
import { Collection } from 'mongodb';
import {
    ActivityDbDoc,
    AppDoc,
    AppProductDoc,
    CalendarDoc,
    DiscussionDoc,
    EventDoc,
    FeedCommentDoc,
    FeedPostDoc,
    ICalDoc,
    InsightDoc,
    MessageDoc,
    OptionalId,
    ProcessDoc,
    ProductDoc,
    ProofOfWorkItem,
    SessionV3Doc,
    TemplateDoc,
    WorkspaceProductDoc,
    File
} from './sharedSchemas';
import { WebhookQueueItem } from './webhook-models';

export const activityFieldCharacterLimits = {
    default: 2048,
    textarea: 65536,
    text: 2048,
    textunit: 2048,
    textpredefinedoptions: 2048,
};

/** List of database collections currently supporting drafting */
export const DraftCollections = new Set(['wallDb', 'pollDb', 'messagesDb']);
export interface CoreDb {
    userDb: Collection<User>;
    fileDb: Collection<File>;
    networkDb: Collection<NetworkDoc>;
    sessionDb: Collection;
    v3sessionDb: Collection<SessionV3Doc>;
    teamDb: Collection<Team>;
    groupDb: Collection<Group>;
    accountDb: Collection<Account>;
    pwdresetDb: Collection;
    tokenLoginDb: Collection<LoginToken>;
    inviteDb: Collection<Invite>;
    tagDb: Collection<Tag>;
    linkDb: Collection<Link>;

    notificationDb: Collection<NotificationItem>;
    deviceDb: Collection;

    processDb: Collection<OptionalId<ProcessDoc>>;
    activitiesDb: Collection<ActivityDbDoc>;
    activitiesRemovedDb: Collection<ActivityDbDoc>;
    wallDb: Collection<FeedPostDoc>;
    wallCommentsDb: Collection<FeedCommentDoc>;
    calendarDb: Collection<OptionalId<CalendarDoc>>;
    icalDb: Collection<ICalDoc>;

    discussionDb: Collection<DiscussionDoc>;
    messagesDb: Collection<MessageDoc>;
    eventDb: Collection<EventDoc>;

    prefixUsageDb: Collection;
    loggerDb: Collection;

    reportingKeyDb: Collection;

    indexKeyStoreDb: Collection<SearchIndexAPIKey>,
    indexResumeLogDb: Collection<SearchIndexResumeLogItem>,
    indexErrorLogDb: Collection<SearchIndexErrorLogItem>,

    rpcStatDb: Collection;
    roleDb: Collection<Role>;
    webhookDb: Collection<WebhookQueueItem>;
    proofOfWorkDb: Collection<ProofOfWorkItem>;
    templateDb: Collection<OptionalId<TemplateDoc>>;
    insightDb: Collection<OptionalId<InsightDoc>>;
    appDb: Collection<AppDoc>;
    /** Used for caching user permissions */
    permissionCacheDb: Collection<PermissionCacheDocument>;
    pollDb: Collection<PollDbDoc>;
    productDb: Collection<OptionalId<ProductDoc>>;
    workspaceProductDb: Collection<OptionalId<WorkspaceProductDoc>>;
    appProductDb: Collection<OptionalId<AppProductDoc>>;
}