/** @format */

import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { FilesService } from './../../_services/files.service';
import { File } from './../../_models/file.model';
import { GMarker } from './../../_models/gmarker.model';
import { Discussion, DiscussionFileResponse } from './../../_models/discussion.model';
import { PersonalSettings } from '../../_models/personal-settings.model';
import { DiscussionLink } from './../../_models/discussionLink.model';
import { Team } from './../../_models/team.model';
import { GMapConf, GmapComponent } from './../../shared/gmap/gmap.component';
import { PeopleService } from 'app/people/people.service';
import { CoreService } from 'app/_services/core.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { TeamService } from 'app/_services/team.service';
import { UpdateUserData, User } from '../../_models/user.model';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { HelperService } from 'app/_services/helper.service';
import { PermissionService } from 'app/_services/permission.service';
import { Company } from '@app/models';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { MessageViewComponent } from 'app/discussion-shared/message-view/message-view.component';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { TranslateService } from 'app/_services/translate.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
    providers: [
        [SelectedDiscussionService],
        { provide: TRANSLOCO_SCOPE, useValue: { scope: 'people-shared/user-detail', alias: 'userdetail' } },
    ],
})
export class UserDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
    @ViewChild('gmap', { static: false }) map: GmapComponent;
    @ViewChild(MessageViewComponent, { static: false }) messageView: MessageViewComponent;

    // TODO: add input for predefined tab?
    @Input() userId: string;
    @Input() initialTab: 'discussions' | 'files' | 'info' | 'location';
    @Input() enableEditProperties: boolean;

    @Output() ready = new EventEmitter<boolean>();

    userTeams: Team[] = [];
    userFields: any[] = [];
    userSeen: string;
    userStatus: string;
    userLocation: string;
    userName: string;
    isManagedUser = false;
    userFieldValueExistence: boolean;
    links: DiscussionLink[] = [];
    filteredLinks: DiscussionLink[] = [];
    discussionAttachments: File[] = [];
    filteredAttachments: File[] = [];
    fileData: any;
    network: Company;
    clearEditFieldEmit: string;
    isItMe: boolean;
    chatMediaSearchForm = new UntypedFormGroup({
        searchInput: new UntypedFormControl(),
    });
    editPropertiesForm = new UntypedFormGroup({
        title: new UntypedFormControl(),
        teams: new UntypedFormControl(),
    });
    me: BehaviorSubject<PersonalSettings>;
    user: User;
    discussion: Discussion;
    personalSettings: PersonalSettings;
    userObservable: Observable<User>;
    hasImages = false;
    hasFiles = false;
    imgPerRow = 3;
    isOnline = false;
    filteredTeams: Team[] = [];
    error: string;
    editingField: string;
    unknownUser: string;
    mapConf = new GMapConf();
    stackSize$: BehaviorSubject<number> = this.sideNavService.stackSize$;
    disableMessenger = true;
    isUserOwner = new BehaviorSubject<boolean>(false);
    screenWidth = window.innerWidth;

    private onDestroy = new Subject<void>();

    constructor (
        public sideNavService: SideNavService,
        public filesService: FilesService,
        public permissions: PermissionService,
        public core: CoreService,
        public teamService: TeamService,
        private rpc: RPCService,
        private people: PeopleService,
        private router: Router,
        private dialogHelperService: DialogHelperService,
        private helpers: HelperService,
        private cdr: ChangeDetectorRef,
        private v3Discussion: V3DiscussionService,
        private selectedDiscussion: SelectedDiscussionService,
        private translocoService: TranslocoService,
        private translateService: TranslateService,
    ) { }

    ngOnInit() {
        this.mapConf = {
            markers: [],
        };

        this.me = this.core.user;
        this.personalSettings = this.core.user?.value;

        this.people
            .getUserAsObservable(this.userId)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (user: User) => {
                    if (!this.editingField) {
                        if (user) {
                            this.user = user;
                        } else {
                            this.user = this.people.getUser(this.userId);
                        }
                        this.translateService.translationLoaded('people-shared/user-detail').then(() => {
                            this.loadUser();
                        });
                    }

                    if (user?.managedUser) {
                        this.isManagedUser = true;
                    }

                    this.ready.emit(true);
                },
            });

        this.core.network.pipe(takeUntil(this.onDestroy)).subscribe({
            next: workspace => {
                if (!workspace) {
                    return;
                }

                this.network = workspace;
                this.isUserOwner.next(workspace.members?.find(({ uid }) => uid === this.user._id)?.owner || false);
            },
        });

        this.checkDiscussionPermission();
    }

    ngAfterViewInit(): void {
        this.changeTab(this.initialTab);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    selectNewDiscussion(discussionId: string) {
        this.v3Discussion
            .get(discussionId)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: discussion => {
                    this.discussion = discussion;
                    this.selectedDiscussion.newContact.next(null);
                    this.cdr.detectChanges();
                },
                error: error => console.error('Failed to fetch a discussion!', error),
            });
    }

    popSidenav() {
        this.sideNavService.pop();
    }

    clearSidenav() {
        this.sideNavService.clear();
    }

    selectPage(page: number) {
        this.tabGroup.selectedIndex = page;
        this.cdr.detectChanges();
    }

    tabChanged(event: MatTabChangeEvent) {
        const label = event.tab.textLabel;

        if (label === 'Files') {
            this.getDiscussionAttachments();
            this.getDiscussionLinks();
        }

        if (label === 'Discussion') {
            setTimeout(() => {
                this.messageView?.scrollToBottom();
                this.selectedDiscussion.focusMessageInput.next();
            }, 500);
        }
    }

    propertyFieldValue(userId: string, fieldId: string) {
        const member = this.core.network.value?.members?.find(({ uid }) => uid === userId);
        return member?.fields?.[fieldId];
    }

    /**
     * Returns list of teams where selected user is member
     */
    getTeams() {
        this.core.teams.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            if (this.user) {
                this.userTeams = this.teamService.getUserTeams(this.user._id);
                this.userTeams.sort((a, b) => (a.name > b.name ? 1 : -1));
                this.setFilteredTeams();
                this.cdr.detectChanges();
            }
        });
    }

    get lastSeen(): string {
        if (this.user.lastSeen) {
            return this.user.lastSeen;
        }
    }

    getUserDisplayName(userId?: string): string {
        if (userId) {
            return this.people.getUser(userId).display_name;
        }
        const uid = this.user._id;
        const user = this.people.getUser(uid);
        this.userName = `${user.firstname} ${user.lastname}`;
    }

    get userTitle(): string {
        const user = this.core.network.value.members.find(member => this.user._id === member.uid);

        if (user?.title) {
            return user.title;
        }
        return this.translocoService.translate('userdetail.no_title_set');
    }

    getUserStatus(status: string): void {
        if (!status) {
            this.translateService.translationLoaded('people-shared/user-detail').then(() => {
                this.userStatus = this.translocoService.translate('userdetail.no_status_set');
            });
        } else {
            this.userStatus = status;
        }
    }

    getUserLocation(location: any): void {
        if (!location) {
            this.translateService.translationLoaded('people-shared/user-detail').then(() => {
                this.userLocation = this.translocoService.translate('userdetail.no_last_location');
            });
        } else {
            this.userLocation = location.name;
        }
    }

    getDiscussionAttachments() {
        if (!this.discussion) {
            return;
        }

        const discussionId = this.discussion._id;

        this.v3Discussion
            .loadFiles(discussionId)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (attachments: DiscussionFileResponse) => {
                    this.discussionAttachments = attachments.files;

                    if (this.chatMediaSearchForm.value.searchInput && this.discussionAttachments) {
                        const searchString = this.chatMediaSearchForm.value.searchInput;

                        const filtered = this.discussionAttachments.filter(file => this.helpers.checkMatch(searchString, file.name));

                        this.filteredAttachments = this.sortAttachmentsByDate(filtered);
                    } else {
                        this.filteredAttachments = this.sortAttachmentsByDate(this.discussionAttachments);
                    }
                },
                error: error => console.error('error', error),
            });
    }

    sortAttachmentsByDate(attachments: File[]): File[] {
        if (!attachments?.length) {
            return [];
        }

        return attachments.sort((a, b) => (a.created < b.created ? 1 : -1));
    }

    getAttachmentUser(uid: string) {
        return this.people.getUser(uid).display_name;
    }

    getDiscussionLinks(): void {
        if (this.discussion) {
            this.v3Discussion
                .links(this.discussion._id)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(
                    (data: DiscussionLink[]) => {
                        this.links = data;

                        if (this.chatMediaSearchForm.value.searchInput && this.links) {
                            const searchString = this.chatMediaSearchForm.value.searchInput;

                            this.filteredLinks = this.links.filter(link => this.helpers.checkMatch(searchString, link.link));
                        } else {
                            this.filteredLinks = this.links;
                        }
                    },
                    (error: any) => {
                        this.links = [];
                        error.log('Error getting discussion links: ', error);
                    }
                );
        }
    }

    openFileSideNav(fileId: string) {
        this.sideNavService.create(FileViewComponent, {
            fileId,
        });
    }

    setFilteredTeams() {
        this.filteredTeams = [];
        this.filteredTeams = Object.values(this.teamService.getTeams()).filter(newTeam => this.userTeams.indexOf(newTeam) < 0);
    }

    async editField(field?: any, other?: string) {
        if (!this.enableEditProperties) {
            return;
        }

        if (field) {
            if ((this.permissions.isNetworkAdmin && field.filledBy !== 'user') || this.isItMe) {
                this.editingField = field._id;
            }
        } else if (other) {
            if (other === 'title') {
                await this.translateService.translationLoaded('people-shared/user-detail');
                this.editPropertiesForm.controls.title.setValue(this.userTitle);
            }
            this.editingField = other;
        }
    }

    getFieldTeams(field: any) {
        const memberTeamMap: string[] = [];
        const teamValues = this.propertyFieldValue(this.user._id, field._id);

        if (field.enableMultiple && teamValues) {
            teamValues.forEach((teamId: string) => {
                memberTeamMap.push(this.teamService.getTeam(teamId).name);
            });
        } else if (!field.enableMultiple && teamValues) {
            memberTeamMap.push(this.teamService.getTeam(teamValues[0]).name);
        }

        return memberTeamMap;
    }

    initSettingsPagePermission() {
        if (!this.enableEditProperties) {
            return;
        }

        this.me.pipe(take(1)).subscribe({
            next: user => {
                if (user.id === this.user._id) {
                    this.isItMe = true;
                } else {
                    this.isItMe = false;
                }
            },
        });
    }

    networkFieldSave(state: boolean) {
        if (state) {
            this.editingField = '';
        }
    }

    clearEditField(fieldId: string) {
        this.editingField = '';
    }

    setUserData(section: string, value: string) {
        this.rpc.request('company.set_user_data', [{ section, uid: this.user._id, value }])
            .pipe(take(1))
            .subscribe({
                error: error => console.error('error', error),
            });
    }

    async saveUserTitle() {
        await this.translateService.translationLoaded('people-shared/user-detail');
        if (this.editPropertiesForm.value.title && this.editPropertiesForm.value.title !== this.userTitle) {
            this.setUserData('title', this.editPropertiesForm.value.title);
            this.editingField = '';
        }
    }

    removeUser(user: User) {
        const confirm = (this.userLocation = this.translocoService.translate('userdetail.confirm_remove'));
        const content = (this.userLocation = `${this.translocoService.translate('userdetail.content_remove')}${this.user.display_name}?`);
        const title = (this.userLocation = this.translocoService.translate('userdetail.remove_user'));

        this.dialogHelperService
            .showConfirm(confirm, content, title)
            .pipe(
                take(1),
                filter(confirmed => !!confirmed),
                switchMap(() => this.rpc.request('company.remove_user', [user._id]))
            )
            .subscribe({
                next: () => this.popSidenav(),
                error: err => console.error('Error removing user: ', err),
            });
    }

    addUserToTeams(state = false) {
        if (!state && this.editPropertiesForm.value.teams) {
            this.editPropertiesForm.value.teams.forEach((teamId: string) =>
                this.teamService
                    .addUsersToTeam(teamId, [this.user._id])
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.getTeams();
                            this.cdr.detectChanges();
                        },
                        error: (error: any) => console.error('error happened while adding users to team', error),
                    })
            );
            this.editPropertiesForm.controls.teams.reset();
        }
    }

    removeUserTeam(teamId: string) {
        this.teamService.removeUserFromTeam(teamId, this.user._id).subscribe({
            next: () => {
                this.getTeams();
                this.cdr.detectChanges();
            },
            error: (error: any) => console.error('error happened while removing user from team', error),
        });
    }

    updateUserOwnerStatus(change: MatCheckboxChange, userId: string): void {
        const updateData: UpdateUserData = {
            section: 'owner',
            uid: userId,
            value: change.checked || false,
        };

        this.rpc.request('company.set_user_data', [updateData]).subscribe({
            error: error => console.error('there was a problem while updating user data', error),
        });
    }

    removeUserPermission(): boolean {
        if (this.isUserOwner.value) {
            return this.permissions.isNetworkAdmin && this.enableEditProperties && !this.isItMe && this.permissions.isNetworkOwner;
        }
        return this.permissions.isNetworkAdmin && this.enableEditProperties && !this.isItMe;
    }

    get inMobileDiscussionView(): boolean {
        return this.screenWidth <= 600;
    }

    private checkDiscussionPermission() {
        const url = this.router.url;

        // TODO: We should add something like this.user.unknown for users not sharing networks with you
        const newContact = url.includes(this.user._id);

        if (!this.discussion && url.includes('discussions') && newContact) {
            this.disableMessenger = true;
            this.cdr.detectChanges();
            return;
        }

        if (this.people.isMockUser(this.user)) {
            this.disableMessenger = true;
            this.cdr.detectChanges();
            return;
        }

        this.disableMessenger = this.discussion ? url.includes(this.discussion._id) : newContact;
        this.cdr.detectChanges();
    }

    private resetUser() {
        this.userTeams = [];
        this.filteredTeams = [];
        this.unknownUser = '';
        this.userFields = [];
        this.editPropertiesForm.reset();
    }

    private loadUser() {
        // TODO: We should add something like this.user.unknown for users not sharing networks with you
        if (this.user && this.user.networks) {
            this.initSettingsPagePermission();
            this.resetUser();
            this.checkUserNetwork();
            this.getDiscussion();
            this.getNetworkFields();
            this.getTeams();
            this.getUserDisplayName();
            this.getUserStatus(this.user.status);
            this.getUserLocation(this.user.lastLocation);
            this.initDiscussionMediaSearch();

            if (this.discussion) {
                this.v3Discussion.v3Message.newMessages.pipe(takeUntil(this.onDestroy)).subscribe({
                    next: newMessages => {
                        if (newMessages.filter(({ type }) => type === 'user')) {
                            return;
                        }

                        this.getDiscussionAttachments();
                        this.getDiscussionLinks();
                    },
                });
            }

            if (this.user.lastLocation) {
                const marker = GMarker.fromUser(this.user);
                if (this.map && this.map.mapLoaded) {
                    this.map.addNewMarker(marker);
                    this.map.panTo(marker.data.getPosition());
                } else {
                    this.mapConf.markers[0] = marker;
                }
            }
        } else if (this.user && !this.user.networks) {
            /* TODO: We should add something like this.user.unknown for users not sharing networks with you
               The translation could be done a bit differently */
            this.getDiscussion();
            this.unknownUser = `${this.translocoService.translate('userdetail.no_common_workspaces1')} ${this.user.firstname} ${this.user.lastname
                } ${this.translocoService.translate('userdetail.no_common_workspaces2')}`;
            this.userName = `${this.user.firstname} ${this.user.lastname}`;
        }
    }

    private checkUserNetwork() {
        if (this.people.isMockUser(this.user)) {
            this.unknownUser = `${this.user.display_name}` + ` ${this.translocoService.translate('userdetail.is_system_user')}`;
            return;
        }

        // TODO: We should add something like this.user.unknown for users not sharing networks with you
        if (this.user.networks) {
            const status = this.user.networks.includes(this.core.network.value._id);
            if (!status) {
                this.unknownUser = `${this.translocoService.translate('userdetail.different_common_workspace1')} ${this.user.display_name
                    } ${this.translocoService.translate('userdetail.different_common_workspace2')}`;
            }
        }
    }

    private getDiscussion() {
        const discussions: Discussion[] = this.v3Discussion.getPrivate(this.user._id);

        if (discussions.length === 1) {
            this.discussion = discussions[0];
        } else {
            this.discussion = null;
        }

        this.checkDiscussionPermission();
        this.selectedDiscussion.newContact.next(this.discussion ? null : this.user._id);
    }

    private getNetworkFields() {
        if (this.core.network.value.propertyFields) {
            this.userFields = Object.keys(this.core.network.value.propertyFields.users).map(
                i => this.core.network.value.propertyFields.users[i]
            );
        }
    }

    private changeTab(view: 'discussions' | 'files' | 'info' | 'location') {
        let tab: MatTab | undefined;
        if (!this.tabGroup?._tabs) {
            console.warn('could not change user detail tab');
            return;
        }

        switch (view) {
            case 'discussions':
                tab = this.tabGroup._tabs.find(matTab => matTab.textLabel === 'Discussion');
                break;
            case 'files':
                tab = this.tabGroup._tabs.find(matTab => matTab.textLabel === 'Files');
                break;
            case 'location':
                tab = this.tabGroup._tabs.find(matTab => matTab.textLabel === 'Location');
                break;
            default:
                tab = this.tabGroup._tabs.find(matTab => matTab.textLabel === 'User Info');
                break;
        }

        if (!tab?.position) {
            console.warn('could not change user detail tab');
            return;
        }

        this.selectPage(tab.position);
    }

    private initDiscussionMediaSearch() {
        this.filteredAttachments = this.sortAttachmentsByDate(this.discussionAttachments);

        this.chatMediaSearchForm.controls.searchInput.valueChanges
            .pipe(takeUntil(this.onDestroy), debounceTime(500))
            .subscribe((searchString: string) => {
                if (this.discussionAttachments) {
                    const filtered = this.discussionAttachments.filter(file => this.helpers.checkMatch(searchString, file.name));

                    this.filteredAttachments = this.sortAttachmentsByDate(filtered);
                }

                if (this.links) {
                    this.filteredLinks = this.links.filter(link => this.helpers.checkMatch(searchString, link.link));
                }
            });
    }
}
